import { useT } from "../../../../common/i18n"
import { usePagination } from "../../../../common/hooks/usePagination"
import { TabsPanel } from "@salesforce/design-system-react"
import GenericDataTable from "../../../../common/ui/genericDataTable/genericDataTable"
import DataTableColumn from "../../../../common/ui/data-table/column"
import * as React from "react"
import { Form } from "../../../../common/ui/form/formik"
import { FormActions, SubmitButtonField } from "../../../../common/ui/form/formElements"
import { Formik } from "formik"
import { download } from "../../../../common/download"
import { Log } from "../../../../common/log"
import Papa from "papaparse"

export const exportHardwareCSV = (multiSelection) => {
    let configMeta = {}
    multiSelection.selections.forEach((device) => {
        for (const [key] of Object.entries(device.initialConfig)) {
            configMeta[key] = true
        }
    })

    let headers = ["serial", "created_at", "uid", "address", "firmware"]
    for (const [key] of Object.entries(configMeta)) {
        headers.push("cfg:" + key)
    }

    let csvDevices = []

    multiSelection.selections.forEach((device) => {
        let exportDev = {
            serial: device.serial,
            created_at: device.createdAt,
            uid: "",
            address: device.addr,
            firmware: device.firmwareVersion || "",
        }
        for (const [key, value] of Object.entries(device.initialConfig)) {
            let csvKey = "cfg:" + key
            exportDev[csvKey] = value
        }
        csvDevices.push(exportDev)
    })

    let csvContent = Papa.unparse(csvDevices, {
        quotes: false, //or array of booleans
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ";",
        header: true,
        newline: "\n",
        skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
        columns: headers, //or array of strings
    })

    download(csvContent, "devices.csv", "text/csv")
}


export const ExportDevicesTab = (props) => {
    const t = useT()
    const page = usePagination()

    let multiSelection = props.multiSelection
    return (
        <TabsPanel label={t("device.bulkoperation.devicetype.label", "Device Type")}>
            <div className="slds-p-horizontal--xx-small">
                <Formik
                    initialValues={{}}
                    onSubmit={() => {
                        Log.Debug("Export Devices: ", multiSelection.selections)
                        exportHardwareCSV(multiSelection)
                    }}
                    render={() => {
                        return (
                            <Form>
                                <FormActions>
                                    <SubmitButtonField iconName={"play"}>Export Devices</SubmitButtonField>
                                </FormActions>
                            </Form>
                        )
                    }}
                />
                <br />
                <h1>{t("device.bulkoperation.table-heading", "Affected Devices")}:</h1>
            </div>

            <GenericDataTable id={"bulk-operation-table"} items={multiSelection.selections} page={page} tableConfigDefault={{}} selection={multiSelection}>
                <DataTableColumn
                    label={t("device.table-config.heading.serial", "Serial")}
                    property={"serial"}
                    render={(props) => {
                        return (
                            <div className="slds-truncate" title={props.item.serial}>
                                <a href={`/#/organisation/devices/${props.item.id}/device-data`}>{props.item.serial}</a>
                            </div>
                        )
                    }}
                />
                <DataTableColumn
                    label={t("device.table-config.heading.createdAt", "Created At")}
                    property={"createdAt"}
                    render={(props) => {
                        return props.item.createdAt
                    }}
                />
                <DataTableColumn
                    label={t("device.table-config.heading.address", "Address")}
                    property="addr"
                    title={"Addr"}
                    render={(props) => {
                        return (
                            <div className="slds-truncate" title={props.item.addr}>
                                <a href={`/#/organisation/devices/${props.item.id}/device-data`}>{props.item.addr}</a>
                            </div>
                        )
                    }}
                />
                <DataTableColumn
                    label={t("device.table-config.heading.name", "Name")}
                    property="name"
                    title={"Name"}
                    render={(props) => {
                        return props.item.name
                    }}
                />
                <DataTableColumn
                    label={t("device.table-config.heading.firmware", "Firmware")}
                    property="firmwareVersion"
                    title={"Firmware"}
                    render={(props) => {
                        return props.item.firmwareVersion
                    }}
                />
                <DataTableColumn
                    label={t("device.table-config.heading.type", "Type")}
                    property={"deviceType"}
                    render={(props) => {
                        return props.item.deviceType.displayName
                    }}
                />
                <DataTableColumn
                    label={t("common.organisation", "Organisation")}
                    property={"Organisation"}
                    render={(props) => {
                        return props.item.organisation ? props.item.organisation.name : ""
                    }}
                />
            </GenericDataTable>
        </TabsPanel>
    )
}
