import React, {useState} from "react";
import {Link, Outlet, useParams} from "react-router-dom";
import Page from "../common/ui/page";
import Button from "../common/slds/buttons/button";
import {useT} from "../common/i18n";
import {useFeatureContext} from "../common/context/featureContext";
import {FeatureNotEnabled} from "../common/featureNotEnabled";

// Disable this page if "lobaro-device-gateway" feature is not enabled
const HardwareActivationPage = () => {
    const license = useFeatureContext()
    const t = useT()
    if (!license.validateFeatures("lobaro-device-gateway")) {
        return <Page title={t("hardware-activation.titel","Hardware Activation")} trail={[]}>
            <FeatureNotEnabled/>
        </Page>
    }
    const routeParams = useParams();
    const [activationCode, setActivationCode] = useState(routeParams.activationCode || "");

    return <Page title={t("hardware-activation.titel","Hardware Activation")} trail={[]}>
        <div className="slds-p-horizontal--medium">

            <div className="slds-grid">
                <div className="slds-size_6-of-6 slds-m-right--small">
                    <div className="slds-text-heading--large slds-m-top--large slds-m-bottom--small">{t("hardware-activation.activate-hardware","Activate Hardware")}</div>
                    <input type={"text"} id="activation-code-input" className="slds-input slds-m-bottom--small"
                           placeholder={t("hardware-activation.input.placeholder","Activation Code")}
                           autoFocus={true}
                           value={activationCode}
                           onChange={(e) => setActivationCode(e.target.value)}
                    />
                    <Link to={"/hardwareActivation/" + activationCode}>
                        <Button>{t("hardware-activation.button.show-hardware","Show Hardware")}</Button>
                    </Link>
                </div>
            </div>

            <Outlet />


        </div>
    </Page>;
};

export default HardwareActivationPage;